@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --squircle-polygon: polygon(
    95.1801% 50%,
    95.1502% 47.1594%,
    95.0579% 44.3079%,
    94.8957% 41.4357%,
    94.6505% 38.5357%,
    94.3035% 35.6049%,
    93.8295% 32.6467%,
    93.1972% 29.673%,
    92.3703% 26.7067%,
    91.3105% 23.7836%,
    89.9819% 20.9514%,
    88.3584% 18.2672%,
    86.4305% 15.7895%,
    84.2105% 13.5695%,
    81.7328% 11.6416%,
    79.0486% 10.0181%,
    76.2164% 8.6895%,
    73.2933% 7.62967%,
    70.327% 6.80284%,
    67.3533% 6.17055%,
    64.3951% 5.69652%,
    61.4643% 5.3495%,
    58.5643% 5.10434%,
    55.6921% 4.94212%,
    52.8406% 4.84983%,
    50% 4.8199%,
    47.1594% 4.84983%,
    44.3079% 4.94212%,
    41.4357% 5.10434%,
    38.5357% 5.3495%,
    35.6049% 5.69652%,
    32.6467% 6.17055%,
    29.673% 6.80284%,
    26.7067% 7.62967%,
    23.7836% 8.6895%,
    20.9514% 10.0181%,
    18.2672% 11.6416%,
    15.7895% 13.5695%,
    13.5695% 15.7895%,
    11.6416% 18.2672%,
    10.0181% 20.9514%,
    8.6895% 23.7836%,
    7.62967% 26.7067%,
    6.80284% 29.673%,
    6.17055% 32.6467%,
    5.69652% 35.6049%,
    5.3495% 38.5357%,
    5.10434% 41.4357%,
    4.94212% 44.3079%,
    4.84983% 47.1594%,
    4.8199% 50%,
    4.84983% 52.8406%,
    4.94212% 55.6921%,
    5.10434% 58.5643%,
    5.3495% 61.4643%,
    5.69652% 64.3951%,
    6.17055% 67.3533%,
    6.80284% 70.327%,
    7.62967% 73.2933%,
    8.6895% 76.2164%,
    10.0181% 79.0486%,
    11.6416% 81.7328%,
    13.5695% 84.2105%,
    15.7895% 86.4305%,
    18.2672% 88.3584%,
    20.9514% 89.9819%,
    23.7836% 91.3105%,
    26.7067% 92.3703%,
    29.673% 93.1972%,
    32.6467% 93.8295%,
    35.6049% 94.3035%,
    38.5357% 94.6505%,
    41.4357% 94.8957%,
    44.3079% 95.0579%,
    47.1594% 95.1502%,
    50% 95.1801%,
    52.8406% 95.1502%,
    55.6921% 95.0579%,
    58.5643% 94.8957%,
    61.4643% 94.6505%,
    64.3951% 94.3035%,
    67.3533% 93.8295%,
    70.327% 93.1972%,
    73.2933% 92.3703%,
    76.2164% 91.3105%,
    79.0486% 89.9819%,
    81.7328% 88.3584%,
    84.2105% 86.4305%,
    86.4305% 84.2105%,
    88.3584% 81.7328%,
    89.9819% 79.0486%,
    91.3105% 76.2164%,
    92.3703% 73.2933%,
    93.1972% 70.327%,
    93.8295% 67.3533%,
    94.3035% 64.3951%,
    94.6505% 61.4643%,
    94.8957% 58.5643%,
    95.0579% 55.6921%,
    95.1502% 52.8406%
  );
}

@layer base {
  /**
   * Hopefully when scrollbar-color lands everywhere, (and not just in FF), we'll be able to keep just this.
   */
  html .no-bg-scrollbar {
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }

  html.dark .no-bg-scrollbar {
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  }

  html .no-bg-scrollbar::-webkit-scrollbar,
  html .no-bg-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  html .no-bg-scrollbar:hover::-webkit-scrollbar-thumb,
  html .no-bg-scrollbar:focus::-webkit-scrollbar-thumb,
  html .no-bg-scrollbar:focus-within::-webkit-scrollbar-thumb,
  html .no-bg-scrollbar:active::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 4px solid transparent;
    background-clip: content-box;
    border-radius: 10px;
  }

  html .no-bg-scrollbar::-webkit-scrollbar-thumb:hover,
  html .no-bg-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.35) !important;
  }

  html.dark .no-bg-scrollbar:hover::-webkit-scrollbar-thumb,
  html.dark .no-bg-scrollbar:focus::-webkit-scrollbar-thumb,
  html.dark .no-bg-scrollbar:focus-within::-webkit-scrollbar-thumb,
  html.dark .no-bg-scrollbar:active::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
  }

  html.dark .no-bg-scrollbar::-webkit-scrollbar-thumb:hover,
  html.dark .no-bg-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: rgba(255, 255, 255, 0.35) !important;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

body,
html {
  -webkit-overflow-scrolling: touch;
}
